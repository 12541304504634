export const TechnicalCommitteeMembers = [
  { name: "Dr. Seungjun Ahn", affiliation: "Hongik University" },
  {
    name: "Dr. Seung Hyun Cha",
    affiliation: "Korea Advanced Institute of Science and Technology",
  },
  { name: "Dr. Soowon Chang", affiliation: "Purdue University" },
  {
    name: "Dr. Xingbin Chen",
    affiliation: "South China Agricultural University",
  },
  { name: "Dr. Byung Joo Choi", affiliation: "Ajou University" },
  { name: "Dr. Jin Ouk Choi", affiliation: "University of Nevada, Las Vegas" },
  { name: "Dr. Tomoyuki Gondo", affiliation: "University of Tokyo" },
  { name: "Dr. Youngjib Ham", affiliation: "Texas A&M University" },
  { name: "Dr. Tian Han", affiliation: "Ritsumeikan University" },
  { name: "Dr. Sungjoo Hwang", affiliation: "Ewha National University" },
  { name: "Dr. Kosei Ishida", affiliation: "Waseda University" },
  {
    name: "Dr. Jun Iwamatsu",
    affiliation: "Research Institute on Building Cost",
  },
  { name: "Dr. Youjin Jang", affiliation: "North Dakota State University" },
  {
    name: "Dr. Wooyong Jung",
    affiliation: "KEPCO International Nuclear Graduate School",
  },
  { name: "Dr. Kyubyung Kang", affiliation: "Purdue University" },
  { name: "Dr. Youngcheol Kang", affiliation: "Yonsei University" },
  { name: "Dr. Daeho Kim", affiliation: "University of Toronto" },
  {
    name: "Dr. Hirotake Kanisawa",
    affiliation: "Shibaura Institute of Technology",
  },
  { name: "Dr. Hongjo Kim", affiliation: "Yonsei University" },
  {
    name: "Dr. Ji-Min Kim",
    affiliation: "Seoul National University of Science & Technology",
  },
  { name: "Dr. Joseph Kim", affiliation: "California State University" },
  { name: "Dr. Jung In Kim", affiliation: "City University of Hong Kong" },
  { name: "Dr. Minkoo Kim", affiliation: "Chungbuk National University" },
  { name: "Dr. Namgyun Kim", affiliation: "University of Dayton" },
  { name: "Dr. Sungjin Kim", affiliation: "Hanbat National University" },
  { name: "Dr. Choongwan Koo", affiliation: "Incheon National University" },
  { name: "Dr. Dongmin Lee", affiliation: "Chungang University" },
  { name: "Dr. Gaang Lee", affiliation: "University of Alberta" },
  { name: "Dr. Minhyun Lee", affiliation: "Hong Kong Polytechnic University" },
  { name: "Dr. Sanghoon Lee", affiliation: "University of Seoul" },
  { name: "Dr. Seulbi Lee", affiliation: "Incheon National University" },
  { name: "Dr. Yongcheol Lee", affiliation: "Louisiana State University" },
  {
    name: "Dr. Gengying Li",
    affiliation: "South China Agricultural University",
  },
  { name: "Dr. Jacob Je-Chian Lin", affiliation: "National Taiwan University" },
  { name: "Dr. Sungkon Moon", affiliation: "Ajou University" },
  { name: "Dr. Yosuke Nagumo", affiliation: "Hopkins Architects" },
  { name: "Dr. Sayaka Nishino", affiliation: "Kyoto University" },
  { name: "Dr. Masatoyo Ogasawara", affiliation: "Tokyo Denki University" },
  { name: "Dr. Jisoo Park", affiliation: "Indiana State University" },
  { name: "Dr. Man-Woo Park", affiliation: "Myongji University" },
  { name: "Dr. Rumiko Sasaki", affiliation: "University of Tokyo" },
  { name: "Dr. JoonOh Seo", affiliation: "Hong Kong Polytechnic University" },
  { name: "Dr. Kazuya Shide", affiliation: "Shibaura Institute of Technology" },
  { name: "Dr. Min Jae Suh", affiliation: "Sam Houston State University" },
  { name: "Dr. Arno Suzuki", affiliation: "Kyoto Tachibana University" },
  { name: "Dr. Atsushi Tamura", affiliation: "Building Research Institute" },
  { name: "Dr. Koji Tanaka", affiliation: "Circle Field Associates Limited" },
  { name: "Dr. Shuhei Tazawa", affiliation: "Toyo University" },
  { name: "Dr. Bin Xue", affiliation: "Chongqing University" },
  {
    name: "Dr. Tomonari Yashiro",
    affiliation: "Tokyo City University, University of Tokyo",
  },
  { name: "Dr. Hao Zheng", affiliation: "City University of Hong Kong" },
  { name: "Dr. Sungmin Yun", affiliation: "Yeungnam University" },
  {
    name: "Dr. Sang-Guk Yum",
    affiliation: "Gangneung-Wonju National University",
  },
];

export const OrganizingMembers = [
  { name: "Dr. Masao Ando", affiliation: "Chiba University" },
  { name: "Dr. Shuzo Furusaka", affiliation: "Ritsumeikan University" },
  { name: "Dr. Tomonari Yashiro", affiliation: "Tokyo City University" },
  {
    name: "Dr. Hirotake Kanisawa",
    affiliation: "Shibaura Institute of Technology",
  },
  { name: "Dr. Kazuya Shide", affiliation: "Shibaura Institute of Technology" },
  { name: "Dr. Masatoyo Ogasawara", affiliation: "Tokyo Denki University" },
  { name: "Dr. Sayaka Nishino", affiliation: "Kyoto University" },
  { name: "Dr. Tomoyuki Gondo", affiliation: "University of Tokyo" },
  { name: "Dr. Kosei Ishida", affiliation: "Waseda University" },
  { name: "Dr. Shuhei Tazawa", affiliation: "Toyo University" },
  { name: "Dr. Tian Han", affiliation: "Ritsumeikan University" },
  { name: "Dr. Rumiko Sasaki", affiliation: "University of Tokyo" },
  {
    name: "Dr. Jun Iwamatsu",
    affiliation: "Research Institute on Building Cost",
  },
  { name: "Dr. Atsushi Tamura", affiliation: "Building Research Institute" },
  {
    name: "Dr. Koichiro Sumino",
    affiliation: "Pacific Century Premium Developments",
  },
  { name: "Dr. Yosuke Nagumo", affiliation: "Hopkins Architects" },
  { name: "Kanako Nishino", affiliation: "IIBH" },
  { name: "Kazunori Katada", affiliation: "Ritsumeikan University" },
  { name: "Nao Shibata", affiliation: "West England University" },
  { name: "Dr. Koji Tanaka", affiliation: "Circle Field Associates Limited" },
  { name: "Dr. JoonOh Seo", affiliation: "Hong Kong Polytechnic University" },
  {
    name: "Dr. BonSang Koo",
    affiliation: "Seoul National University of Science and Technology",
  },
];
